/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.filters {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    gap: 1rem;
}

.filterSelector {
    min-width: 200px;
}

.checkKey {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--mui-palette-text-secondary);
}

.main {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 320px minmax(0, 1fr);
    gap: 2rem;
}

.facetedFilters {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.chip {
    border-radius: 6px;
    padding: 0px 6px;
}

.checkChip {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.checkChip>svg {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

.noIssuesFound {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
}

.noIssuesFound>svg {
    width: 10rem;
    height: 10rem;
    color: var(--mui-palette-primary-main);
}

.pullRequestTitle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    width: fit-content;
}

.pullRequestTitle>svg {
    width: 1rem;
    height: 1rem;
}
