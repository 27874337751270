/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.options {
    position: sticky;
    /* 1rem from .container padding */
    top: calc(var(--header-height) + 1rem);
}

.listItemButton {
    border-radius: 2px;
}

.listItemSelectedButton {
    background-color: var(--mui-palette-primary-main);
}

.listItemSelectedIcon {
    color: var(--mui-palette-primary-main);
}

.listItemSelectedText {
    color: var(--mui-palette-primary-main);
    font-weight: bold;
}