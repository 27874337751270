/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.helperDrawer {
    padding: 2rem;
    min-width: 300px;
    max-width: 700px;
}

.helperDrawer a {
    color: var(--mui-palette-primary-main);
}

.inlineCode {
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    font-family: 'Manrope', sans-serif;
}

.inlineCode[data-dark-mode-on="true"] {
    background-color: var(--mui-palette-grey-800);
}

.inlineCode[data-dark-mode-on="false"] {
    background-color: var(--mui-palette-grey-200);
}

.codeBlock {
    position: relative;
}

.copyButton {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}

.copyButton svg {
    width: 18px;
    height: 18px;
}

.errorFallbackContainer {
    display: grid;
    align-content: center;
    justify-items: center;
    height: 100%;
    gap: 8px;
}