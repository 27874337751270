/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.facetedFilterOption {
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    gap: 0.5rem;
}

.items {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}
