/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.tableHeadCell {
    border-bottom: 1px dashed rgb(241, 243, 244);
    color: rgb(99, 115, 129);
    font-weight: 600;
    background-color: rgb(244, 246, 248);
}

.tableHeadCellLabelActive {
    /* !impotant to override the default MUI color */
    color: var(--mui-palette-primary-main) !important;
}

.tableHeadCellLabelActive>svg {
    fill: var(--mui-palette-primary-main);
}

[data-mui-color-scheme="dark"] .tableHeadCell {
    border-bottom: 1px dashed rgb(46, 50, 54);
    color: rgb(145, 158, 171);
    background-color: rgba(145, 158, 171, 0.12);
}

.noDataLabel {
    color: var(--mui-palette-text-secondary);
    font-style: italic;
    text-align: center;
}

.linkIcon {
    width: 1rem;
    height: 1rem;
}
