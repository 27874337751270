/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.container {
    display: grid;
    gap: 1rem;
    align-content: start;
}