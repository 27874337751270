/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.container {
    display: grid;
    gap: 1rem;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
}

.home {
    justify-self: center;
}