/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 3rem;
    padding: 1rem 2rem;
    align-items: center;
    height: var(--header-height);
}

.reviewpadLogo {
    width: 150px;
    height: 40px;
    fill: white;
    cursor: pointer;
}

.userOptions {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.organizationSelect {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    max-width: 650px;
}

.addOrganizationButton {
    color: white;
}