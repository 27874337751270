/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.container {
    display: grid;
    grid-auto-rows: auto 1fr auto;
    min-height: 100vh;
}

.main {
    display: grid;
    grid-auto-columns: auto auto 1fr;
    grid-auto-flow: column;
}