/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.subtitle {
    padding: 1rem 0;
    color: var(--mui-palette-text-secondary);
}
