/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

html,
body,
#root {
    height: 100%;
}

body,
html {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --header-height: 88px;

    /*
        The global variables from MUI are also imported.
        You can check how here: https://mui.com/material-ui/experimental-api/css-theme-variables/usage/

        The variables are flattened and prefixed with --mui by default and
        followed by the path to the variable in the MUI theme object.
        You can check the theme object here: https://mui.com/material-ui/customization/default-theme/
        e.g. --mui-palette-primary-main
    */
    /* palette */
    --mui-palette-primary-main: #007AFF;
    --mui-palette-primary-mainChannel: 0 122 255;
    /* shadows */
    --mui-shadows-1: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
        rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}

[data-mui-color-scheme="dark"] body {
    /* palette */
    --mui-palette-primary-main: #C50769;
    --mui-palette-primary-mainChannel: 197 7 105;
    /* shadows */
    --mui-shadows-1: rgba(0, 0, 0, 0.2) 0px 0px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 12px 24px -4px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}